<template>
  <div>
    <Header
      :showBackButton="true"
      :showSearchIcon="false"
      customPageName=" "
      :smallContainer="true"
    />

    <section class="page-content page-anim2">
      <div class="container pb-2">
        <h1 class="title text-blue my-1 section-title">{{ t("Contato") }}</h1>
        <p class="text-desc text-brown desktop6">
          {{
            t(
              "Opine, sugira posts que você considera importantes e reporte erros que você constatou em nosso aplicativo! Estamos aqui para resolver e te trazer as melhores soluções."
            )
          }}
        </p>

        <div class="textarea-holder">
          <textarea
            v-model="form.message"
            :placeholder="t('Sua mensagem') + '...'"
          ></textarea>
        </div>

        <transition enter-active-class="animated fadeIn">
          <p
            v-show="warningMessage != ''"
            class="text-desc text-red text-center mt-1"
          >
            {{ warningMessage }}
          </p>
        </transition>

        <transition enter-active-class="animated fadeIn">
          <p
            v-show="formSent"
            class="text-desc text-blue text-bold text-center mt-1"
          >
            {{ t("Obrigado pela mensagem!") }}
          </p>
        </transition>

        <button @click="sendForm()" class="default mt-2">
          <div v-show="loading" class="loading-spinner"></div>
          <span v-show="!loading">{{ t("Enviar") }}</span>
        </button>

        <div class="text-desc text-bold text-blue text-center other-contacts">
          {{ t("Outros canais de contato:") }}
        </div>
        <div class="page-footer">
          <a
            class="email"
            href="https://api.whatsapp.com/send?phone=5567998859874&text=Olá"
            target="_blank"
          >
            <font-awesome-icon :icon="['fab', 'whatsapp']" />
            <span class="text-blue">{{
              t("Clique aqui e fale com nosso suporte via WhatsApp!")
            }}</span>
          </a>
          <div class="social">
            <a href="https://www.instagram.com/app.doit/" target="_blank">
              <font-awesome-icon :icon="['fab', 'instagram']" />
            </a>
            <a href="https://www.facebook.com/appdoit.com.br/" target="_blank">
              <font-awesome-icon :icon="['fab', 'facebook']" />
            </a>
            <a href="mailto:contato@appdoit.com.br">
              <font-awesome-icon :icon="['fas', 'envelope']" />
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import http from "@/http";
import Header from "../components/Header";
import authentication from "../util/authentication";
export default {
  name: "Contato",
  components: {
    Header,
  },
  data() {
    return {
      form: {
        message: "",
      },
      loading: false,
      warningMessage: "",
      formSent: false,
    };
  },
  methods: {
    sendForm() {
      if (!this.form.message) {
        this.warningMessage =
          this.$translate.locale["Por favor, digite a sua mensagem"];
        return;
      }

      this.warningMessage = "";
      this.loading = true;

      http
        .post(
          "user/message",
          JSON.stringify(this.form.message),
          authentication.getHeaders()
        )
        .then(() => {
          this.form.message = "";
          this.formSent = true;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
textarea {
  position: relative;
  font-family: fontRegular;
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 190px;
  padding: 10px 15px;
  margin: 15px auto 0 auto;
  font-size: 1.4em;
  border-radius: 15px;
  outline: none;
  resize: none;
  border: 1px solid #e2b6a1;
  color: #0f2651;
  letter-spacing: 1px;
}
button.default {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.other-contacts {
  margin-top: 58px;
}

.page-footer {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  a {
    &.email {
      display: flex;
      align-items: center;
      gap: 0 14px;
      svg {
        margin-left: 0;
      }
      span {
        font-size: 1.4em;
      }
    }
    svg {
      font-size: 1.8em;
      color: var(--blue);
      margin-left: 15px;
    }
  }
}

@media only screen and (min-width: 1140px) {
  .page-footer {
    margin-top: 50px;
    a {
      &.email {
        gap: 0 18px;
        svg {
          margin-left: 0;
        }
        span {
          font-size: 1.8em;
        }
      }
      svg {
        font-size: 2.2em;
        color: var(--blue);
        margin-left: 15px;
      }
    }
  }
}
</style>
